import logo from 'assets/img/illustrations/vizure-icon.png';

const vizureSiteConfig = {
  global: {
    name: 'Vizure',
    homeRoute: '/errors/comingsoon',
    homeRouteAuthenticated: '/errors/comingsoon',
    logo,
    isFluid: false,
    isRTL: false,
    isDark: true,
    navbarPosition: 'top',
    showBurgerMenu: false, // controls showing vertical nav on mobile
    currency: '$',
    isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
    navbarStyle: 'transparent',
    navRoutes: []
  }
};

export default vizureSiteConfig;
