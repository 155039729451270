import logo from 'assets/img/illustrations/realtor.png';

const realtorSiteConfig = {
  global: {
    name: 'Realtor',
    homeRoute: '/app/homes',
    homeRouteAuthenticated: '/app/homes',
    logo,
    isFluid: true,
    isRTL: false,
    isDark: false,
    navbarPosition: 'top',
    showBurgerMenu: false, // controls showing vertical nav on mobile
    currency: '$',
    isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
    navbarStyle: 'transparent',
    navRoutes: [
      {
        name: 'My Homes',
        to: '/app/homes/list',
        requireAuth: true
      },
      {
        name: 'Add New Home',
        to: '/app/homes/create',
        requireAuth: true
      }
    ]
  }
};

export default realtorSiteConfig;
