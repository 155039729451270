import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import { Redirect, Switch } from 'react-router';
import Section from 'components/common/Section';
import LazyRoute from 'components/utilities/LazyRoute';

const Error404 = React.lazy(() =>
  import(/* webpackChunkName: "Error404" */ 'components/errors/Error404')
);
const Error500 = React.lazy(() =>
  import(/* webpackChunkName: "Error500" */ 'components/errors/Error500')
);
const ComingSoon = React.lazy(() =>
  import(/* webpackChunkName: "ComingSoon" */ 'components/pages/ComingSoon')
);

const ErrorLayout = ({ match: { url } }) => {
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
          <Logo />
          <Switch>
            <LazyRoute path={`${url}/404`} component={Error404} />
            <LazyRoute path={`${url}/500`} component={Error500} />
            <LazyRoute path={`${url}/comingsoon`} component={ComingSoon} />
            <Redirect to={`${url}/404`} />
          </Switch>
        </Col>
      </Row>
    </Section>
  );
};

ErrorLayout.propTypes = {
  match: PropTypes.object
};

export default ErrorLayout;
