import { createSlice } from '@reduxjs/toolkit';
// import {
//   collection,
//   doc,
//   getDocs,
//   getFirestore,
//   query,
//   serverTimestamp,
//   setDoc
// } from 'firebase/firestore';
// import { triGram } from 'helpers/utils';

const initialState = {};

// export const serializeUser = user => ({
//   _user: {
//     uid: user.uid,
//     photo: user.photo,
//     name: user.name
//   }
// });

// const GENERATION_OFFSET = new Date('5000-01-01').getTime();
// const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
// export const generateId = () => {
//   let autoId = '';
//   for (let i = 0; i < 10; i++) {
//     autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
//   }
//   return (GENERATION_OFFSET - Date.now()).toString(32) + autoId;
// };

// export const serializeTrigram = txt => {
//   const _smeta = {};
//   triGram((txt || '').slice(0, 128)).forEach(key => (_smeta[key] = true));
//   return {
//     _smeta
//   };
// };

// export const addPost = createAsyncThunk(
//   'post/addPost',
//   async (inputDoc, options = {}) => {
//     const {
//       postType = 'article',
//       user,
//       content,
//       visibility = 'private',
//       ...rest
//     } = inputDoc;

//     console.error('ADDING POST', inputDoc, options);
//     if (!user) return;

//     const db = getFirestore();
//     const payload = {
//       ...rest,
//       postType,
//       content,
//       visibility,
//       ...serializeUser(user),
//       _created: serverTimestamp(),
//       _modified: serverTimestamp(),
//       ...serializeTrigram(content)
//     };
//     payload._ameta = [user.uid, visibility];

//     if (postType === 'article') {
//       payload.qtStatus = 'set';
//       payload.qtCollection = postType;
//       payload.qtLon = Math.random() * 360 - 180;
//       payload.qtLat = Math.random() * 180 - 90;
//     }

//     const id = generateId();
//     payload._id = id;
//     console.error('posting', id, payload);
//     const postRef = doc(db, 'posts', id);
//     await setDoc(postRef, payload);

//     if (options.useFeed) {
//       const followersQuery = query(
//         collection(db, 'users', user.uid, 'followerCollection')
//       );
//       const followersSnapshot = await getDocs(followersQuery);
//       for (let followerDoc of followersSnapshot.docs) {
//         await addPost({
//           user,
//           postType: options.useFeed,
//           content,
//           visibility: 'private',
//           refPostId: id,
//           users: followerDoc.data().users,
//           ...(options.feedOptions || {})
//         });
//       }
//     }

//     if (postType === 'article') {
//       // await axiosApiInstance.post(`${apiBase}/api/quadtree/add`, {
//       //   postId: id,
//       //   x: Math.random() * 360 - 180,
//       //   y: Math.random() * 180 - 90,
//       // });
//     }

//     // setDoc(
//     //   doc(db, "site", "settings"),
//     //   {
//     //     lastPost: {
//     //       _user: payload._user,
//     //       _created: payload._created,
//     //       id: payload._id,
//     //     },
//     //   },
//     //   { merge: true }
//     // );

//     // // Note, we don't wait on this call to finish. It will update the feed in the background
//     // axiosApiInstance.put(`${apiBase}/api/post/add/${id}`, {
//     //   preventFeed: options.preventFeed,
//     // });
//     return postRef;
//   }
// );

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {}
});

export const { increment, decrement, incrementByAmount } = postSlice.actions;

export default postSlice.reducer;
