import React from 'react';
import {
  Nav,
  Tooltip,
  OverlayTrigger,
  NavDropdown,
  Card
} from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import CartNotification from 'components/navbar/top/CartNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NineDotMenu from './NineDotMenu';
import { useConfig, useSession } from 'firebase/session';
import { useDispatch, useSelector } from 'react-redux';
import { setConfig } from 'reducers/configSlice';
import Login from 'components/authentication/simple/Login';

const TopNavRightSideNavItem = () => {
  const config = useConfig();
  const dispatch = useDispatch();
  const {
    config: { isDark, isRTL }
  } = useSelector(state => state);
  const user = useSession();
  if (!user) {
    return (
      <Nav
        navbar
        className="navbar-nav-icons ms-auto flex-row align-items-center"
        as="ul"
      >
        <NavDropdown title="Login" align="end">
          <Card className="navbar-card-login shadow-none">
            <Card.Body className="fs--1 fw-normal p-4">
              <Login />
            </Card.Body>
          </Card>
        </NavDropdown>
      </Nav>
    );
  }
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <Nav.Item as={'li'}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => dispatch(setConfig('isDark', !isDark))}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip id="ThemeColor">
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>

      {config.showCart && <CartNotification />}
      {config.showNotifications && <NotificationDropdown />}
      {config.showNineDot && <NineDotMenu />}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
