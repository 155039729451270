import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'br'],
  allowedAttributes: {
    a: ['href']
  },
  allowedIframeHostnames: ['www.youtube.com']
};

export default html => ({ __html: sanitizeHtml(html, defaultOptions) });
