import { createSlice } from '@reduxjs/toolkit';
import { getSiteConfig } from 'locales/i18n';

const initialState = {
  ...getSiteConfig().global
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setKey: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    reset: state => {
      Object.assign(state, {
        // ...initialState
      });
    }
  },
  extraReducers: builder => {
    builder.addCase('user/setUser', (state, action) => {
      if (!action.payload) return;
      if (action.payload.isDark !== undefined) {
        state.isDark = action.payload.isDark;
      }
    });
  }
});

export const { setKey, reset } = configSlice.actions;

export const setConfig = (key, value) => dispatch => {
  dispatch(setKey({ key, value }));
};

export default configSlice.reducer;
