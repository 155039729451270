import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const LazyRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={() => (
      <Suspense fallback={<>Loading...</>}>
        <Component />
      </Suspense>
    )}
  />
);

LazyRoute.propTypes = {
  component: PropTypes.object.isRequired
};

export default LazyRoute;
