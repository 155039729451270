import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { useSession } from 'firebase/session';
import { useTranslation } from 'react-i18next';

const ProfileDropdown = () => {
  const { t } = useTranslation();
  const user = useSession();
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={user.photo} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item className="fw-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>{t('profile.gopro')}</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href="#!">{t('profile.setStatus')}</Dropdown.Item>
          <Dropdown.Item as={Link} to="/user/profile">
            {t('profile.profileAndAccount')}
          </Dropdown.Item>
          <Dropdown.Item href="#!">{t('profile.feedback')}</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/user/settings">
            {t('profile.settings')}
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/authentication/card/logout">
            {t('profile.logout')}
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
