import React from 'react';
import { Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { initializeApp } from 'firebase/app';
import { useReduxAuth } from 'firebase/session';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'helpers/history';
import { getColor } from 'helpers/utils';
import useToggleStyle from 'hooks/useToggleStyle';
import './App.css';
import 'animate.css';

dayjs.extend(relativeTime);

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBZkr8-d2wKrZwoo5CqUeJcFE6x_bsJ6HA',
  authDomain: 'vizureweb.firebaseapp.com',
  databaseURL: 'https://vizureweb.firebaseio.com',
  projectId: 'vizureweb',
  storageBucket: 'vizureweb.appspot.com',
  messagingSenderId: '309333016823',
  appId: '1:309333016823:web:a79530d238a5ee1d'
};

// Initialize Firebase
initializeApp(firebaseConfig);

// console.error('lennox 1', process.env);
// if (process.env.BUILD_ENV === 'debug') {
//   console.error('lennox 2', process.env.BUILD_ENV);
//   import('@firebase/firestore').then(module => {
//     console.error('lennox 3', module);
//     // const db = getFirestore();
//     // connectFirestoreEmulator(db, 'localhost', 8080);
//   });
// }

const App = () => {
  useReduxAuth();
  const config = useSelector(state => state.config);
  const dispatch = useDispatch();

  const { isLoaded } = useToggleStyle(config.isRTL, config.isDark, dispatch);

  const { initializing } = useSelector(state => state.user);

  if (!isLoaded || initializing) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      />
    );
  }
  return (
    <Router basename={process.env.PUBLIC_URL} history={history}>
      <Layout />
    </Router>
  );
};

export default App;
