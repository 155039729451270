// Make sure to only include the library in development
if (process.env.BUILD_ENV === 'development') {
  console.error('WHY DID I RENDER INITIALIZED');
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true
  });
}

import React from 'react';
import ReactDOM from 'react-dom';
import './locales/i18n';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

ReactDOM.render(
  <React.StrictMode>
    <Main>
      <App />
    </Main>
  </React.StrictMode>,
  document.getElementById('main')
);
