/* eslint-disable react/prop-types */
import React from 'react';
import { Switch, Redirect, useLocation, Route } from 'react-router-dom';
import LazyRoute from 'components/utilities/LazyRoute';

const Accordion = React.lazy(() =>
  import(
    /* webpackChunkName: "Accordion" */ 'components/doc-components/Accordion'
  )
);
const Alerts = React.lazy(() =>
  import(/* webpackChunkName: "Alerts" */ 'components/doc-components/Alerts')
);
const Badges = React.lazy(() =>
  import(/* webpackChunkName: "Badges" */ 'components/doc-components/Badges')
);
const Breadcrumbs = React.lazy(() =>
  import(
    /* webpackChunkName: "Breadcrumbs" */ 'components/doc-components/Breadcrumb'
  )
);
const Buttons = React.lazy(() =>
  import(/* webpackChunkName: "Buttons" */ 'components/doc-components/Buttons')
);
const CalendarExample = React.lazy(() =>
  import(
    /* webpackChunkName: "CalendarExample" */ 'components/doc-components/CalendarExample'
  )
);
const Cards = React.lazy(() =>
  import(/* webpackChunkName: "Cards" */ 'components/doc-components/Cards')
);
const Dropdowns = React.lazy(() =>
  import(
    /* webpackChunkName: "Dropdowns" */ 'components/doc-components/Dropdowns'
  )
);
const ListGroups = React.lazy(() =>
  import(
    /* webpackChunkName: "ListGroups" */ 'components/doc-components/ListGroups'
  )
);
const Modals = React.lazy(() =>
  import(/* webpackChunkName: "Modals" */ 'components/doc-components/Modals')
);
const Offcanvas = React.lazy(() =>
  import(
    /* webpackChunkName: "Offcanvas" */ 'components/doc-components/Offcanvas'
  )
);
const Pagination = React.lazy(() =>
  import(
    /* webpackChunkName: "Pagination" */ 'components/doc-components/Pagination'
  )
);
const BasicProgressBar = React.lazy(() =>
  import(
    /* webpackChunkName: "BasicProgressBar" */ 'components/doc-components/ProgressBar'
  )
);
const Spinners = React.lazy(() =>
  import(
    /* webpackChunkName: "Spinners" */ 'components/doc-components/Spinners'
  )
);
const Toasts = React.lazy(() =>
  import(/* webpackChunkName: "Toasts" */ 'components/doc-components/Toasts')
);
const Avatar = React.lazy(() =>
  import(/* webpackChunkName: "Avatar" */ 'components/doc-components/Avatar')
);
const Image = React.lazy(() =>
  import(/* webpackChunkName: "Image" */ 'components/doc-components/Image')
);
const Tooltips = React.lazy(() =>
  import(
    /* webpackChunkName: "Tooltips" */ 'components/doc-components/Tooltips'
  )
);
const Popovers = React.lazy(() =>
  import(
    /* webpackChunkName: "Popovers" */ 'components/doc-components/Popovers'
  )
);
const Figures = React.lazy(() =>
  import(/* webpackChunkName: "Figures" */ 'components/doc-components/Figures')
);
const Hoverbox = React.lazy(() =>
  import(
    /* webpackChunkName: "Hoverbox" */ 'components/doc-components/Hoverbox'
  )
);
const Tables = React.lazy(() =>
  import(/* webpackChunkName: "Tables" */ 'components/doc-components/Tables')
);
const FormControl = React.lazy(() =>
  import(
    /* webpackChunkName: "FormControl" */ 'components/doc-components/FormControl'
  )
);
const InputGroup = React.lazy(() =>
  import(
    /* webpackChunkName: "InputGroup" */ 'components/doc-components/InputGroup'
  )
);
const Select = React.lazy(() =>
  import(/* webpackChunkName: "Select" */ 'components/doc-components/Select')
);
const Checks = React.lazy(() =>
  import(/* webpackChunkName: "Checks" */ 'components/doc-components/Checks')
);
const Range = React.lazy(() =>
  import(/* webpackChunkName: "Range" */ 'components/doc-components/Range')
);
const FormLayout = React.lazy(() =>
  import(
    /* webpackChunkName: "FormLayout" */ 'components/doc-components/FormLayout'
  )
);
const FloatingLabels = React.lazy(() =>
  import(
    /* webpackChunkName: "FloatingLabels" */ 'components/doc-components/FloatingLabels'
  )
);
const FormValidation = React.lazy(() =>
  import(
    /* webpackChunkName: "FormValidation" */ 'components/doc-components/FormValidation'
  )
);
const BootstrapCarousel = React.lazy(() =>
  import(
    /* webpackChunkName: "BootstrapCarousel" */ 'components/doc-components/BootstrapCarousel'
  )
);
const SlickCarousel = React.lazy(() =>
  import(
    /* webpackChunkName: "SlickCarousel" */ 'components/doc-components/SlickCarousel'
  )
);
const Navs = React.lazy(() =>
  import(/* webpackChunkName: "Navs" */ 'components/doc-components/Navs')
);
const Navbars = React.lazy(() =>
  import(/* webpackChunkName: "Navbars" */ 'components/doc-components/Navbars')
);
const Tabs = React.lazy(() =>
  import(/* webpackChunkName: "Tabs" */ 'components/doc-components/Tabs')
);
const Collapse = React.lazy(() =>
  import(
    /* webpackChunkName: "Collapse" */ 'components/doc-components/Collapse'
  )
);
const CountUp = React.lazy(() =>
  import(/* webpackChunkName: "CountUp" */ 'components/doc-components/CountUp')
);
const Embed = React.lazy(() =>
  import(/* webpackChunkName: "Embed" */ 'components/doc-components/Embed')
);
const Background = React.lazy(() =>
  import(
    /* webpackChunkName: "Background" */ 'components/doc-components/Backgrounds'
  )
);
const Search = React.lazy(() =>
  import(/* webpackChunkName: "Search" */ 'components/doc-components/Search')
);
const VerticalNavbar = React.lazy(() =>
  import(
    /* webpackChunkName: "VerticalNavbar" */ 'components/doc-components/VerticalNavbar'
  )
);
const NavBarTop = React.lazy(() =>
  import(
    /* webpackChunkName: "NavBarTop" */ 'components/doc-components/NavBarTop'
  )
);
const ComboNavbar = React.lazy(() =>
  import(
    /* webpackChunkName: "ComboNavbar" */ 'components/doc-components/ComboNavbar'
  )
);
const TypedText = React.lazy(() =>
  import(
    /* webpackChunkName: "TypedText" */ 'components/doc-components/TypedText'
  )
);
const FileUploader = React.lazy(() =>
  import(
    /* webpackChunkName: "FileUploader" */ 'components/doc-components/FileUploader'
  )
);
const Borders = React.lazy(() =>
  import(/* webpackChunkName: "Borders" */ 'components/utilities/Borders')
);
const Colors = React.lazy(() =>
  import(/* webpackChunkName: "Colors" */ 'components/utilities/Colors')
);
const ColoredLinks = React.lazy(() =>
  import(
    /* webpackChunkName: "ColoredLinks" */ 'components/utilities/ColoredLinks'
  )
);
const Display = React.lazy(() =>
  import(/* webpackChunkName: "Display" */ 'components/utilities/Display')
);
const Visibility = React.lazy(() =>
  import(/* webpackChunkName: "Visibility" */ 'components/utilities/Visibility')
);
const StretchedLink = React.lazy(() =>
  import(
    /* webpackChunkName: "StretchedLink" */ 'components/utilities/StretchedLink'
  )
);
const Float = React.lazy(() =>
  import(/* webpackChunkName: "Float" */ 'components/utilities/Float')
);
const Position = React.lazy(() =>
  import(/* webpackChunkName: "Position" */ 'components/utilities/Position')
);
const Spacing = React.lazy(() =>
  import(/* webpackChunkName: "Spacing" */ 'components/utilities/Spacing')
);
const Sizing = React.lazy(() =>
  import(/* webpackChunkName: "Sizing" */ 'components/utilities/Sizing')
);
const TextTruncation = React.lazy(() =>
  import(
    /* webpackChunkName: "TextTruncation" */ 'components/utilities/TextTruncation'
  )
);
const Typography = React.lazy(() =>
  import(/* webpackChunkName: "Typography" */ 'components/utilities/Typography')
);
const VerticalAlign = React.lazy(() =>
  import(
    /* webpackChunkName: "VerticalAlign" */ 'components/utilities/VerticalAlign'
  )
);
const Flex = React.lazy(() =>
  import(/* webpackChunkName: "Flex" */ 'components/utilities/Flex')
);
const Grid = React.lazy(() =>
  import(/* webpackChunkName: "Grid" */ 'components/utilities/Grid')
);
const WizardForms = React.lazy(() =>
  import(
    /* webpackChunkName: "WizardForms" */ 'components/doc-components/WizardForms'
  )
);
const GettingStarted = React.lazy(() =>
  import(
    /* webpackChunkName: "GettingStarted" */ 'components/documentation/GettingStarted'
  )
);
const Configuration = React.lazy(() =>
  import(
    /* webpackChunkName: "Configuration" */ 'components/documentation/Configuration'
  )
);
const DarkMode = React.lazy(() =>
  import(/* webpackChunkName: "DarkMode" */ 'components/documentation/DarkMode')
);
const Plugins = React.lazy(() =>
  import(/* webpackChunkName: "Plugins" */ 'components/documentation/Plugins')
);
const Styling = React.lazy(() =>
  import(/* webpackChunkName: "Styling" */ 'components/documentation/Styling')
);
const DesignFile = React.lazy(() =>
  import(
    /* webpackChunkName: "DesignFile" */ 'components/documentation/DesignFile'
  )
);
const Starter = React.lazy(() =>
  import(/* webpackChunkName: "Starter" */ 'components/pages/Starter')
);

const DatePicker = React.lazy(() =>
  import(
    /* webpackChunkName: "DatePicker" */ 'components/doc-components/DatePicker'
  )
);
const FontAwesome = React.lazy(() =>
  import(
    /* webpackChunkName: "FontAwesome" */ 'components/doc-components/FontAwesome'
  )
);
const Changelog = React.lazy(() =>
  import(
    /* webpackChunkName: "Changelog" */ 'components/documentation/change-log/ChangeLog'
  )
);
const Analytics = React.lazy(() =>
  import(/* webpackChunkName: "Anlytics" */ 'components/dashboards/analytics')
);
const Crm = React.lazy(() =>
  import(/* webpackChunkName: "Crm" */ 'components/dashboards/crm')
);
const Saas = React.lazy(() =>
  import(/* webpackChunkName: "Saas" */ 'components/dashboards/saas')
);
const ECommerce = React.lazy(() =>
  import(/* webpackChunkName: "ECommerce" */ 'components/dashboards/e-commerce')
);
const Profile = React.lazy(() =>
  import(
    /* webpackChunkName: "Profile" */ 'components/pages/user/profile/Profile'
  )
);
const Associations = React.lazy(() =>
  import(
    /* webpackChunkName: "Associations" */ 'components/pages/asscociations/Associations'
  )
);
const Followers = React.lazy(() =>
  import(
    /* webpackChunkName: "Followers" */ 'components/app/social/followers/Followers'
  )
);
const Notifications = React.lazy(() =>
  import(
    /* webpackChunkName: "Notifications" */ 'components/app/social/notifications/Notifications'
  )
);
const ActivityLog = React.lazy(() =>
  import(
    /* webpackChunkName: "ActivityLog" */ 'components/app/social/activity-log/ActivityLog'
  )
);
const Settings = React.lazy(() =>
  import(
    /* webpackChunkName: "Settings" */ 'components/pages/user/settings/Settings'
  )
);
const Feed = React.lazy(() =>
  import(/* webpackChunkName: "Feed" */ 'components/app/social/feed/Feed')
);
const Placeholder = React.lazy(() =>
  import(
    /* webpackChunkName: "Placeholder" */ 'components/doc-components/Placeholder'
  )
);
const Lightbox = React.lazy(() =>
  import(
    /* webpackChunkName: "Lightbox" */ 'components/doc-components/Lightbox'
  )
);
const AdvanceTableExamples = React.lazy(() =>
  import(
    /* webpackChunkName: "AdvanceTableExamples" */ 'components/doc-components/AdvanceTableExamples'
  )
);
const ModalAuth = React.lazy(() =>
  import(
    /* webpackChunkName: "ModalAuth" */ 'components/authentication/modal/ModalAuth'
  )
);
const Calendar = React.lazy(() =>
  import(/* webpackChunkName: "Calendar" */ 'components/app/calendar/Calendar')
);
const FaqAlt = React.lazy(() =>
  import(/* webpackChunkName: "FaqAlt" */ 'components/pages/faq/faq-alt/FaqAlt')
);
const FaqBasic = React.lazy(() =>
  import(
    /* webpackChunkName: "FaqBasic" */ 'components/pages/faq/faq-basic/FaqBasic'
  )
);
const FaqAccordion = React.lazy(() =>
  import(
    /* webpackChunkName: "FaqAccordion" */ 'components/pages/faq/faq-accordion/FaqAccordion'
  )
);
const PrivacyPolicy = React.lazy(() =>
  import(
    /* webpackChunkName: "PrivacyPolicy" */ 'components/pages/miscellaneous/privacy-policy/PrivacyPolicy'
  )
);
const InvitePeople = React.lazy(() =>
  import(
    /* webpackChunkName: "InvitePeople" */ 'components/pages/miscellaneous/invite-people/InvitePeople'
  )
);
const PricingDefault = React.lazy(() =>
  import(
    /* webpackChunkName: "PricingDefault" */ 'components/pages/pricing/pricing-default/PricingDefault'
  )
);
const PricingAlt = React.lazy(() =>
  import(
    /* webpackChunkName: "PricingAlt" */ 'components/pages/pricing/pricing-alt/PricingAlt'
  )
);
const Invoice = React.lazy(() =>
  import(/* webpackChunkName: "Invoice" */ 'components/app/e-commerce/Invoice')
);
const Billing = React.lazy(() =>
  import(
    /* webpackChunkName: "Billing" */ 'components/app/e-commerce/billing/Billing'
  )
);
const Checkout = React.lazy(() =>
  import(
    /* webpackChunkName: "Checkout" */ 'components/app/e-commerce/checkout/Checkout'
  )
);
const ShoppingCart = React.lazy(() =>
  import(
    /* webpackChunkName: "ShoppingCart" */ 'components/app/e-commerce/cart/ShoppingCart'
  )
);
const CustomersDetails = React.lazy(() =>
  import(
    /* webpackChunkName: "CustomersDetails" */ 'components/app/e-commerce/customers-details/CustomersDetails'
  )
);
const OrderDetails = React.lazy(() =>
  import(
    /* webpackChunkName: "OrderDetails" */ 'components/app/e-commerce/orders/order-details/OrderDetails'
  )
);
const Products = React.lazy(() =>
  import(
    /* webpackChunkName: "Products" */ 'components/app/e-commerce/product/Products'
  )
);
const ProductDetails = React.lazy(() =>
  import(
    /* webpackChunkName: "ProductDetails" */ 'components/app/e-commerce/product/product-details/ProductDetails'
  )
);
const HomeFullDetailsLoader = React.lazy(() =>
  import(
    /* webpackChunkName: "HomeFullDetailsLoader" */ 'components/app/homes/home-details/HomeFullDetailsLoader'
  )
);
const Orders = React.lazy(() =>
  import(
    /* webpackChunkName: "Orders" */ 'components/app/e-commerce/orders/order-list/Orders'
  )
);
const Customers = React.lazy(() =>
  import(
    /* webpackChunkName: "Customers" */ 'components/app/e-commerce/customers/Customers'
  )
);
const CreateEvent = React.lazy(() =>
  import(
    /* webpackChunkName: "CreateEvent" */ 'components/app/events/create-an-event/CreateEvent'
  )
);
const EventList = React.lazy(() =>
  import(
    /* webpackChunkName: "EventList" */ 'components/app/events/event-list/EventList'
  )
);
const EventDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "EventDetail" */ 'components/app/events/event-detail/EventDetail'
  )
);
const EmailDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "EmailDetail" */ 'components/app/email/email-detail/EmailDetail'
  )
);
const Compose = React.lazy(() =>
  import(
    /* webpackChunkName: "Compose" */ 'components/app/email/compose/Compose'
  )
);
const Inbox = React.lazy(() =>
  import(/* webpackChunkName: "Inbox" */ 'components/app/email/inbox/Inbox')
);
const EmailProvider = React.lazy(() =>
  import(
    /* webpackChunkName: "EmailProvider" */ 'components/app/email/EmailProvider'
  )
);
const Rating = React.lazy(() =>
  import(/* webpackChunkName: "Rating" */ 'components/doc-components/Rating')
);
const AdvanceSelect = React.lazy(() =>
  import(
    /* webpackChunkName: "AdvanceSelect" */ 'components/doc-components/AdvanceSelect'
  )
);
const Editor = React.lazy(() =>
  import(/* webpackChunkName: "Editor" */ 'components/doc-components/Editor')
);
const Chat = React.lazy(() =>
  import(/* webpackChunkName: "Chat" */ 'components/app/chat/Chat')
);
const Management = React.lazy(() =>
  import(
    /* webpackChunkName: "Management" */ 'components/dashboards/project-management'
  )
);
const Kanban = React.lazy(() =>
  import(/* webpackChunkName: "Kanban" */ 'components/app/kanban/Kanban')
);
const DraggableExample = React.lazy(() =>
  import(
    /* webpackChunkName: "DraggableExample" */ 'components/doc-components/DraggableExample'
  )
);
const LineCharts = React.lazy(() =>
  import(
    /* webpackChunkName: "LineCharts" */ 'components/doc-components/charts-example/echarts/line-charts'
  )
);
const BarCharts = React.lazy(() =>
  import(
    /* webpackChunkName: "BarCharts" */ 'components/doc-components/charts-example/echarts/bar-charts'
  )
);
const CandlestickCharts = React.lazy(() =>
  import(
    /* webpackChunkName: "CandlestickCharts" */ 'components/doc-components/charts-example/echarts/candlestick-charts'
  )
);
const GeoMaps = React.lazy(() =>
  import(
    /* webpackChunkName: "GeoMaps" */ 'components/doc-components/charts-example/echarts/geo-map'
  )
);
const ScatterCharts = React.lazy(() =>
  import(
    /* webpackChunkName: "ScatterCharts" */ 'components/doc-components/charts-example/echarts/scatter-charts'
  )
);
const PieCharts = React.lazy(() =>
  import(
    /* webpackChunkName: "PieCharts" */ 'components/doc-components/charts-example/echarts/pie-charts'
  )
);
const RadarCharts = React.lazy(() =>
  import(
    /* webpackChunkName: "RadarCharts" */ 'components/doc-components/charts-example/echarts/radar-charts/Index'
  )
);
const HeatmapCharts = React.lazy(() =>
  import(
    /* webpackChunkName: "HeatmapCharts" */ 'components/doc-components/charts-example/echarts/heatmap-chart'
  )
);
const Chartjs = React.lazy(() =>
  import(
    /* webpackChunkName: "Chartjs" */ 'components/doc-components/charts-example/chartjs'
  )
);
const HowToUse = React.lazy(() =>
  import(
    /* webpackChunkName: "HowToUse" */ 'components/doc-components/charts-example/echarts/HowToUse'
  )
);
const GoogleMapExample = React.lazy(() =>
  import(
    /* webpackChunkName: "GoogleMapExample" */ 'components/doc-components/GoogleMapExample'
  )
);
const LeafletMapExample = React.lazy(() =>
  import(
    /* webpackChunkName: "LeafletMapExample" */ 'components/doc-components/LeafletMapExample'
  )
);
const CookieNoticeExample = React.lazy(() =>
  import(
    /* webpackChunkName: "CookieNoticeExample" */ 'components/doc-components/CookieNoticeExample'
  )
);
const Scrollbar = React.lazy(() =>
  import(
    /* webpackChunkName: "Scrollbar" */ 'components/doc-components/Scrollbar'
  )
);
const Scrollspy = React.lazy(() =>
  import(
    /* webpackChunkName: "Scrollspy" */ 'components/doc-components/Scrollspy'
  )
);
const ReactIcons = React.lazy(() =>
  import(
    /* webpackChunkName: "ReactIcons" */ 'components/doc-components/ReactIcons'
  )
);
const ReactPlayerExample = React.lazy(() =>
  import(
    /* webpackChunkName: "ReactPlayerExample" */ 'components/doc-components/ReactPlayerExample'
  )
);
const EmojiMartExample = React.lazy(() =>
  import(
    /* webpackChunkName: "EmojiMartExample" */ 'components/doc-components/EmojiMart'
  )
);
const TreeviewExample = React.lazy(() =>
  import(
    /* webpackChunkName: "TreeviewExample" */ 'components/doc-components/TreeviewExample'
  )
);
const Widgets = React.lazy(() =>
  import(/* webpackChunkName: "Widgets" */ 'widgets/Widgets')
);
const IconBuilder = React.lazy(() =>
  import(
    /* webpackChunkName: "IconBuilder" */ 'components/app/icon-builder/IconBuilder'
  )
);
const CreateHome = React.lazy(() =>
  import(
    /* webpackChunkName: "CreateHome" */ 'components/app/homes/create-home/CreateHome'
  )
);
const EditHome = React.lazy(() =>
  import(
    /* webpackChunkName: "EditHome" */ 'components/app/homes/create-home/EditHome'
  )
);
const HomeLanding = React.lazy(() =>
  import(
    /* webpackChunkName: "HomeLanding" */ 'components/app/homes/HomeLanding'
  )
);
const HomeList = React.lazy(() =>
  import(/* webpackChunkName: "HomeList" */ 'components/app/homes/HomeList')
);
const HomeMapLanding = React.lazy(() =>
  import(
    /* webpackChunkName: "HomeMapLanding" */ 'components/app/homes/HomeMapLanding'
  )
);

const DemoPage = React.lazy(() =>
  import(/* webpackChunkName: "DemoPage" */ 'components/app/demos/DemoPage')
);

const DataImport = React.lazy(() =>
  import(/* webpackChunkName: "DataImport" */ 'components/app/demos/DataImport')
);

const EmailRoutes = ({ match: { url } }) => (
  <EmailProvider>
    <Switch>
      <LazyRoute path={`${url}/email-detail`} exact component={EmailDetail} />
      <LazyRoute path={`${url}/inbox`} exact component={Inbox} />
      <LazyRoute path={`${url}/compose`} exact component={Compose} />
      {/*Redirect*/}
      <Redirect to="/errors/404" />
    </Switch>
  </EmailProvider>
);
const ECommerceRoutes = ({ match: { url } }) => (
  <Switch>
    <LazyRoute
      path={`${url}/orders/order-details`}
      exact
      component={OrderDetails}
    />
    <LazyRoute path={`${url}/orders/order-list`} exact component={Orders} />
    <LazyRoute path={`${url}/invoice`} exact component={Invoice} />
    <LazyRoute path="/e-commerce/billing" exact component={Billing} />
    <LazyRoute path="/e-commerce/checkout" exact component={Checkout} />
    <LazyRoute
      path="/e-commerce/shopping-cart"
      exact
      component={ShoppingCart}
    />
    <LazyRoute path="/e-commerce/customers" exact component={Customers} />
    <LazyRoute
      path="/e-commerce/customer-details"
      exact
      component={CustomersDetails}
    />
    <LazyRoute
      path={`${url}/product/product-details/:productId`}
      exact
      component={ProductDetails}
    />
    <LazyRoute
      path={`${url}/product/product-details/`}
      exact
      component={ProductDetails}
    />
    <LazyRoute
      path="/e-commerce/product/:productLayout"
      exact
      component={Products}
    />
    <LazyRoute path="/e-commerce/invoice" exact component={Invoice} />
    {/*Redirect*/}
    <Redirect to="/errors/404" />
  </Switch>
);

const MainRoutes = () => {
  const location = useLocation();
  return (
    <Switch>
      {/*Dashboard*/}

      <LazyRoute path="/dashboard/analytics" exact component={Analytics} />
      <LazyRoute path="/dashboard/crm" exact component={Crm} />
      <LazyRoute path="/dashboard/saas" exact component={Saas} />
      <LazyRoute path="/dashboard/e-commerce" exact component={ECommerce} />
      <LazyRoute
        path="/dashboard/project-management"
        exact
        component={Management}
      />

      {/* E Commerce */}
      <Route path="/e-commerce" component={ECommerceRoutes} />

      {/*icons*/}
      <LazyRoute path="/icons/font-awesome" exact component={FontAwesome} />
      <LazyRoute path="/icons/react-icons" exact component={ReactIcons} />

      {/* maps */}
      <LazyRoute path="/maps/google-map" exact component={GoogleMapExample} />
      <LazyRoute path="/maps/leaflet-map" exact component={LeafletMapExample} />

      {/*App*/}
      <LazyRoute path="/app/homes/create" exact component={CreateHome} />
      <LazyRoute path="/app/homes/edit/:homeId" exact component={EditHome} />
      <LazyRoute
        path="/app/homes/home/:homeId"
        exact
        component={HomeFullDetailsLoader}
      />
      <LazyRoute path="/app/homes" exact component={HomeLanding} />
      <LazyRoute path="/app/homes/map" exact component={HomeMapLanding} />
      <LazyRoute path="/app/homes/list" exact component={HomeList} />
      <LazyRoute path="/app/icon-builder" exact component={IconBuilder} />
      <LazyRoute path="/app/calendar" exact component={Calendar} />
      <LazyRoute path="/app/chat" exact component={Chat} />
      <LazyRoute path="/app/kanban" exact component={Kanban} />
      <LazyRoute path="/social/feed" exact component={Feed} />
      <LazyRoute path="/social/activity-log" exact component={ActivityLog} />
      <LazyRoute path="/social/notifications" exact component={Notifications} />
      <LazyRoute path="/social/followers" exact component={Followers} />
      <LazyRoute path="/events/event-detail" exact component={EventDetail} />
      <LazyRoute path="/events/create-an-event" exact component={CreateEvent} />
      <LazyRoute path="/events/event-list" exact component={EventList} />
      <LazyRoute path="/app/demos" exact component={DemoPage} />
      <LazyRoute path="/app/data-import" exact component={DataImport} />
      <LazyRoute path="/app/demos/:demo" exact component={DemoPage} />

      {/* Email */}
      <Route path="/email" component={EmailRoutes} />

      {/*Pages*/}
      <LazyRoute path="/pages/starter" exact component={Starter} />
      <LazyRoute path="/user/profile" exact component={Profile} />
      <LazyRoute path="/user/settings" exact component={Settings} />
      <LazyRoute
        path="/miscellaneous/associations"
        exact
        component={Associations}
      />
      <LazyRoute path="/faq/faq-alt" exact component={FaqAlt} />
      <LazyRoute path="/faq/faq-basic" exact component={FaqBasic} />
      <LazyRoute path="/faq/faq-accordion" exact component={FaqAccordion} />
      <LazyRoute
        path="/pricing/pricing-default"
        exact
        component={PricingDefault}
      />
      <LazyRoute path="/pricing/pricing-alt" exact component={PricingAlt} />
      <LazyRoute
        path="/miscellaneous/privacy-policy"
        exact
        component={PrivacyPolicy}
      />
      <LazyRoute
        path="/miscellaneous/invite-people"
        exact
        component={InvitePeople}
      />
      {/* charts-example */}

      <LazyRoute path="/charts/chartjs" exact component={Chartjs} />
      <LazyRoute
        path="/charts/echarts/line-charts"
        exact
        component={LineCharts}
      />
      <LazyRoute
        path="/charts/echarts/bar-charts"
        exact
        component={BarCharts}
      />
      <LazyRoute
        path="/charts/echarts/candlestick-charts"
        exact
        component={CandlestickCharts}
      />
      <LazyRoute path="/charts/echarts/geo-map" exact component={GeoMaps} />
      <LazyRoute
        path="/charts/echarts/scatter-charts"
        exact
        component={ScatterCharts}
      />
      <LazyRoute
        path="/charts/echarts/pie-charts"
        exact
        component={PieCharts}
      />
      <LazyRoute
        path="/charts/echarts/radar-charts"
        exact
        component={RadarCharts}
      />
      <LazyRoute
        path="/charts/echarts/heatmap-charts"
        exact
        component={HeatmapCharts}
      />
      <LazyRoute path="/charts/echarts/how-to-use" exact component={HowToUse} />

      {/*Components*/}
      <LazyRoute path="/components/alerts" exact component={Alerts} />
      <LazyRoute path="/components/accordion" exact component={Accordion} />
      <LazyRoute path="/components/badges" exact component={Badges} />
      <LazyRoute path="/components/breadcrumb" exact component={Breadcrumbs} />
      <LazyRoute path="/components/buttons" exact component={Buttons} />
      <LazyRoute
        path="/components/calendar"
        exact
        component={CalendarExample}
      />
      <LazyRoute path="/components/cards" exact component={Cards} />
      <LazyRoute path="/components/dropdowns" exact component={Dropdowns} />
      <LazyRoute path="/components/list-group" exact component={ListGroups} />
      <LazyRoute path="/components/modals" exact component={Modals} />
      <LazyRoute path="/components/offcanvas" exact component={Offcanvas} />
      <LazyRoute path="/components/pagination" exact component={Pagination} />
      <LazyRoute
        path="/components/progress-bar"
        exact
        component={BasicProgressBar}
      />
      <LazyRoute path="/components/placeholder" exact component={Placeholder} />
      <LazyRoute path="/components/spinners" exact component={Spinners} />
      <LazyRoute path="/components/toasts" exact component={Toasts} />
      <LazyRoute path="/components/pictures/avatar" exact component={Avatar} />
      <LazyRoute path="/components/pictures/images" exact component={Image} />
      <LazyRoute
        path="/components/pictures/figures"
        exact
        component={Figures}
      />
      <LazyRoute
        path="/components/pictures/hoverbox"
        exact
        component={Hoverbox}
      />
      <LazyRoute
        path="/components/pictures/lightbox"
        exact
        component={Lightbox}
      />
      <LazyRoute path="/components/tooltips" exact component={Tooltips} />
      <LazyRoute path="/components/popovers" exact component={Popovers} />
      <LazyRoute
        path="/components/draggable"
        exact
        component={DraggableExample}
      />
      <LazyRoute path="/components/scrollspy" exact component={Scrollspy} />
      <LazyRoute
        path="/components/treeview"
        exact
        component={TreeviewExample}
      />
      <LazyRoute
        path="/components/carousel/bootstrap"
        exact
        component={BootstrapCarousel}
      />
      <LazyRoute
        path="/components/carousel/slick"
        exact
        component={SlickCarousel}
      />
      <LazyRoute path="/components/navs-and-tabs/navs" exact component={Navs} />
      <LazyRoute path="/tables/basic-tables" exact component={Tables} />
      <LazyRoute
        path="/tables/advance-tables"
        exact
        component={AdvanceTableExamples}
      />
      <LazyRoute
        path="/forms/basic/form-control"
        exact
        component={FormControl}
      />
      <LazyRoute path="/forms/basic/input-group" exact component={InputGroup} />
      <LazyRoute path="/forms/basic/select" exact component={Select} />
      <LazyRoute path="/forms/basic/checks" exact component={Checks} />
      <LazyRoute path="/forms/basic/range" exact component={Range} />
      <LazyRoute path="/forms/basic/layout" exact component={FormLayout} />
      <LazyRoute
        path="/forms/advance/date-picker"
        exact
        component={DatePicker}
      />
      <LazyRoute path="/forms/advance/editor" exact component={Editor} />
      <LazyRoute
        path="/forms/advance/emoji-button"
        exact
        component={EmojiMartExample}
      />
      <LazyRoute
        path="/forms/advance/advance-select"
        exact
        component={AdvanceSelect}
      />
      <LazyRoute
        path="/forms/advance/file-uploader"
        exact
        component={FileUploader}
      />
      <LazyRoute path="/forms/advance/rating" exact component={Rating} />
      <LazyRoute
        path="/forms/floating-labels"
        exact
        component={FloatingLabels}
      />
      <LazyRoute path="/forms/validation" exact component={FormValidation} />
      <LazyRoute path="/forms/wizard" exact component={WizardForms} />
      <LazyRoute
        path="/components/navs-and-tabs/navbar"
        exact
        component={Navbars}
      />
      <LazyRoute path="/components/navs-and-tabs/tabs" exact component={Tabs} />
      <LazyRoute path="/components/collapse" exact component={Collapse} />
      <LazyRoute
        path="/components/cookie-notice"
        exact
        component={CookieNoticeExample}
      />
      <LazyRoute path="/components/countup" exact component={CountUp} />
      <LazyRoute path="/components/videos/embed" exact component={Embed} />
      <LazyRoute
        path="/components/videos/react-player"
        exact
        component={ReactPlayerExample}
      />
      <LazyRoute path="/components/background" exact component={Background} />
      <LazyRoute path="/components/search" exact component={Search} />
      <LazyRoute path="/components/typed-text" exact component={TypedText} />
      <LazyRoute
        path="/components/navs-and-tabs/vertical-navbar"
        exact
        component={VerticalNavbar}
      />
      <LazyRoute
        path="/components/navs-and-tabs/top-navbar"
        exact
        component={NavBarTop}
      />
      <LazyRoute
        path="/components/navs-and-tabs/combo-navbar"
        exact
        component={ComboNavbar}
      />

      {/*Utilities*/}
      <LazyRoute path="/utilities/borders" exact component={Borders} />
      <LazyRoute path="/utilities/colors" exact component={Colors} />
      <LazyRoute
        path="/utilities/colored-links"
        exact
        component={ColoredLinks}
      />
      <LazyRoute path="/utilities/display" exact component={Display} />
      <LazyRoute path="/utilities/visibility" exact component={Visibility} />
      <LazyRoute
        path="/utilities/stretched-link"
        exact
        component={StretchedLink}
      />
      <LazyRoute
        path="/utilities/stretched-link"
        exact
        component={StretchedLink}
      />
      <LazyRoute path="/utilities/float" exact component={Float} />
      <LazyRoute path="/utilities/position" exact component={Position} />
      <LazyRoute path="/utilities/spacing" exact component={Spacing} />
      <LazyRoute path="/utilities/sizing" exact component={Sizing} />
      <LazyRoute
        path="/utilities/text-truncation"
        exact
        component={TextTruncation}
      />
      <LazyRoute path="/utilities/typography" exact component={Typography} />
      <LazyRoute
        path="/utilities/vertical-align"
        exact
        component={VerticalAlign}
      />
      <LazyRoute path="/utilities/flex" exact component={Flex} />
      <LazyRoute path="/utilities/grid" exact component={Grid} />
      <LazyRoute path="/utilities/scroll-bar" exact component={Scrollbar} />

      <LazyRoute path="/widgets" exact component={Widgets} />

      {/*Documentation*/}
      <LazyRoute
        path="/documentation/getting-started"
        exact
        component={GettingStarted}
      />
      <LazyRoute
        path="/documentation/configuration"
        exact
        component={Configuration}
      />
      <LazyRoute path="/documentation/styling" exact component={Styling} />
      <LazyRoute path="/documentation/dark-mode" exact component={DarkMode} />
      <LazyRoute path="/documentation/plugin" exact component={Plugins} />
      <LazyRoute
        path="/documentation/design-file"
        exact
        component={DesignFile}
      />
      <LazyRoute path="/changelog" exact component={Changelog} />
      <LazyRoute path="/authentication-modal" component={ModalAuth} />

      {/*Redirect*/}
      <Redirect
        to={`/errors/404?returnTo=${encodeURIComponent(location.pathname)}`}
      />
    </Switch>
  );
};

export default MainRoutes;
