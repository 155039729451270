import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import avatar from '../assets/img/team/avatar.png';
import bg from '../assets/img/generic/4.jpg';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'reducers/userslice';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

export const useConfig = () => {
  const config = useSelector(state => state.config);
  return config;
};

export const useSession = () => {
  const user = useSelector(state => state.user.user);
  return user;
};

export const withAuth = Component => props => {
  const location = useLocation();
  const { push } = useHistory();
  const { initializing, user } = useSelector(state => state.user);

  React.useEffect(() => {
    if (!initializing && !user) {
      push(
        `/authentication/card/login?returnTo=${encodeURIComponent(
          location.pathname
        )}`
      );
    }
  }, [initializing, push, user]);

  if (initializing || !user) {
    return <></>;
  }

  return <Component {...props} />;
};

export const waitAuth = Component => props => {
  const { initializing } = useSelector(state => state.user);

  if (initializing) {
    return <></>;
  }

  return <Component {...props} />;
};

export const useReduxAuth = () => {
  const dispatch = useDispatch();
  async function onChange(user) {
    if (!user) {
      dispatch(setUser(null));
      return;
    }

    let newUser = {
      uid: user.uid,
      name: user.displayName || (user.email || '').split('@')[0],
      photo: user.photo || user.photoURL || avatar,
      bg: bg,
      providerId: user.providerId,
      email: user.email
    };

    const db = getFirestore();
    const docSnap = await getDoc(doc(db, 'users', user.uid));
    const docData = docSnap.exists() ? docSnap.data() : {};
    newUser = {
      ...newUser,
      ...docData
    };

    dispatch(setUser(newUser));
  }

  React.useEffect(() => {
    // listen for auth state changes
    const unsubscribe = onAuthStateChanged(getAuth(), onChange);

    // unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, []);
};
