import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import siteConfig from './siteConfig';
import iconBuilder from './sites/iconBuilderSiteConfig';
import realtorSiteConfig from './sites/realtorSiteConfig';
import deepMerge from 'deepmerge';
import vizureSiteConfig from './sites/vizureSiteConfig';
import demosSiteConfig from './sites/demosSiteConfig';

let currentSiteConfig = deepMerge(siteConfig, {});

export const debugEnabled =
  window.location.href.indexOf('debug') >= 0 ||
  window.location.host.indexOf('test') >= 0;

export const getSiteConfig = () => {
  return currentSiteConfig;
};

if (window.location.hostname.indexOf('icons.vizure.net') >= 0) {
  currentSiteConfig = deepMerge(currentSiteConfig, iconBuilder);
} else if (
  window.location.hostname.indexOf('files.vizure.net') >= 0 ||
  window.location.hostname.indexOf('home.vizure.net') >= 0 ||
  window.location.hostname.indexOf('homes.vizure.net') >= 0
) {
  currentSiteConfig = deepMerge(currentSiteConfig, realtorSiteConfig);
} else if (window.location.hostname.indexOf('demos.vizure.net') >= 0) {
  currentSiteConfig = deepMerge(currentSiteConfig, demosSiteConfig);
} else {
  currentSiteConfig = deepMerge(currentSiteConfig, vizureSiteConfig);
}

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: currentSiteConfig
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    keySeparator: '.',
    nsSeparator: '::',
    react: {
      useSuspense: false
    }
  });

export default i18n;
