import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';

import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from 'components/common/Toast';
import { useSession } from 'firebase/session';
import { useTranslation } from 'react-i18next';

// import SettingsToggle from 'components/settings-panel/SettingsToggle';
// import SettingsPanel from 'components/settings-panel/SettingsPanel';
import ErrorLayout from './ErrorLayout';
import LazyRoute from 'components/utilities/LazyRoute';
import { debugEnabled } from 'locales/i18n';

const SettingsPanel = React.lazy(() =>
  import(
    /* webpackChunkName: "SettingsPanel" */ 'components/settings-panel/SettingsPanel'
  )
);

const SettingsToggle = React.lazy(() =>
  import(
    /* webpackChunkName: "SettingsToggle" */ 'components/settings-panel/SettingsToggle'
  )
);

const AuthSimpleLayout = React.lazy(() =>
  import(/* webpackChunkName: "AuthSimpleLayout" */ './AuthSimpleLayout')
);
const AuthCardRoutes = React.lazy(() =>
  import(
    /* webpackChunkName: "AuthCardRoutes" */ 'components/authentication/card/AuthCardRoutes'
  )
);
const AuthSplitRoutes = React.lazy(() =>
  import(
    /* webpackChunkName: "AuthSplitRoutes" */ 'components/authentication/split/AuthSplitRoutes'
  )
);
const WizardAuth = React.lazy(() =>
  import(
    /* webpackChunkName: "WizardAuth" */ 'components/authentication/wizard/WizardAuth'
  )
);
const Landing = React.lazy(() =>
  import(/* webpackChunkName: "Landing" */ 'components/pages/landing/Landing')
);

const Layout = () => {
  const { t } = useTranslation();
  const user = useSession();
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const location = useLocation();

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Switch>
        <Route
          path="/"
          exact
          render={() => (
            <Redirect
              to={
                user
                  ? t('global.homeRouteAuthenticated')
                  : t('global.homeRoute')
              }
            />
          )}
        />
        <LazyRoute path="/landing" component={Landing} />
        <Route path="/errors" component={ErrorLayout} />
        <LazyRoute path="/authentication/simple" component={AuthSimpleLayout} />
        <LazyRoute path="/authentication/card" component={AuthCardRoutes} />
        <LazyRoute path="/authentication/split" component={AuthSplitRoutes} />
        <LazyRoute path="/authentication/wizard" component={WizardAuth} />
        <Route component={MainLayout} />
        <Redirect
          to={`/errors/404?from=${encodeURIComponent(location.pathname)}`}
        />
      </Switch>
      {debugEnabled && (
        <React.Suspense fallback={null}>
          <SettingsToggle />
          <SettingsPanel />
        </React.Suspense>
      )}
      <ToastContainer
        transition={Fade}
        closeButton={CloseButton}
        closeOnClick
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
