import React from 'react';
import NavbarDropdown from './NavbarDropdown';
import {
  dashboardRoutes,
  appRoutes,
  pagesRoutes,
  modulesRoutes,
  documentationRoutes
} from 'routes/routes';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { flatRoutes } from 'helpers/utils';
import NavbarDropdownApp from './NavbarDropdownApp';
import NavbarDropdownPages from './NavbarDropdownPages';
import NavbarDropdownModules from './NavbarDropdownModules';
import { useDispatch, useSelector } from 'react-redux';
import { setConfig } from 'reducers/configSlice';
import { useConfig, useSession } from 'firebase/session';
import NavbarNavLink from './NavbarNavLink';

const NavbarTopDropDownMenus = () => {
  const config = useConfig();
  const user = useSession();
  const dispatch = useDispatch();
  const {
    config: { navbarCollapsed, showBurgerMenu }
  } = useSelector(state => state);

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      dispatch(setConfig('navbarCollapsed', !navbarCollapsed));
    }
    if (showBurgerMenu) {
      dispatch(setConfig('showBurgerMenu', !showBurgerMenu));
    }
  };

  const renderLinks = arr => {
    return arr.map(route => {
      if (route.requireAuth && !user) return null;
      if (route.to) {
        return <NavbarNavLink key={route.name} route={route} />;
      }
      if (route.children) {
        return (
          <NavbarDropdown title={route.name}>
            {renderLinks(route.children)}
          </NavbarDropdown>
        );
      }
    });
  };
  const navRoutes = config.navRoutes;
  return (
    <>
      {navRoutes && renderLinks(navRoutes)}
      {!navRoutes && (
        <>
          <NavbarDropdown title="dashboard">
            {dashboardRoutes.children[0].children.map(route => (
              <Dropdown.Item
                key={route.name}
                as={Link}
                className={route.active ? 'link-600' : 'text-500'}
                to={route.to}
                onClick={handleDropdownItemClick}
              >
                {route.name}
              </Dropdown.Item>
            ))}
          </NavbarDropdown>

          <NavbarDropdown title="app">
            <NavbarDropdownApp items={appRoutes.children} />
          </NavbarDropdown>

          <NavbarDropdown title="pages">
            <NavbarDropdownPages items={pagesRoutes.children} />
          </NavbarDropdown>

          <NavbarDropdown title="modules">
            <NavbarDropdownModules items={modulesRoutes.children} />
          </NavbarDropdown>

          <NavbarDropdown title="documentation">
            {flatRoutes(documentationRoutes.children).map(route => (
              <Dropdown.Item
                key={route.name}
                as={Link}
                className={route.active ? 'link-600' : 'text-500'}
                to={route.to}
                onClick={handleDropdownItemClick}
              >
                {route.name}
              </Dropdown.Item>
            ))}
          </NavbarDropdown>
        </>
      )}
    </>
  );
};

export default NavbarTopDropDownMenus;
