import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword
} from 'firebase/auth';
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc
} from 'firebase/firestore';
import { history } from 'helpers/history';
import { triGram } from 'helpers/utils';
import { debugEnabled } from 'locales/i18n';
// import { toast } from 'react-toastify';

const initialState = {
  initializing: true,
  user: null,
  isPending: false,
  error: undefined
};

export const logOut = createAsyncThunk(
  'user/logOut',
  async () => await getAuth().signOut()
);
export const logIn = createAsyncThunk(
  'user/logIn',
  async ({ email, password }) => {
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error('Failed email login', err.code, err.message);
      throw new Error(err.code || 'Failed email login');
    }
  }
);

async function setupInitialUser({ userCredential, name, first, last }) {
  const db = getFirestore();
  await setDoc(doc(db, 'users', userCredential.user.uid), {
    uid: userCredential.user.uid,
    first: first,
    last: last,
    name
  });

  await setDoc(doc(db, 'profile', userCredential.user.uid), {
    uid: userCredential.user.uid,
    name
  });
}

export const providerLogin = createAsyncThunk(
  'user/providerLogin',
  async providerName => {
    let provider = null;
    switch (providerName) {
      case 'google':
        provider = new GoogleAuthProvider();
        break;
      default:
        throw new Error('Unknown provider : ' + provider);
    }

    let userCredential;
    try {
      const auth = getAuth();
      userCredential = await signInWithPopup(auth, provider);
      const db = getFirestore();
      const userSnap = await getDoc(doc(db, 'users', userCredential.user.uid));
      if (!userSnap.exists()) {
        const userPayload = {
          name: userCredential.user.displayName,
          userCredential,
          first: userCredential.user.displayName.split(' ')[0],
          last: userCredential.user.displayName.split(' ')[1] || ''
        };
        if (userCredential.user.photoURL) {
          userPayload.photo = userCredential.user.photoURL;
        }
        await setupInitialUser(userPayload);
      }
      // console.error('Logged In', userCredential);
    } catch (err) {
      // console.error('Failed social login', err.code, err.message);
      throw new Error(err.code || 'Failed social login');
    }
    // const userCredential = await signInWithProvider({ providerName: 'google' });
    // return userCredential;
  }
);

export const createUser = createAsyncThunk(
  'user/createUser',
  async ({ email, password, confirmPassword, name, first = '', last = '' }) => {
    if (password !== confirmPassword) {
      throw new Error('auth.error.passwordDoesntMatch');
    }
    const auth = getAuth();

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const db = getFirestore();
      const userRef = doc(db, 'users', userCredential.user.uid);
      await setupInitialUser({
        userRef,
        name: name || email.split('@')[0],
        userCredential,
        first,
        last
      });
    } catch (err) {
      console.error('Failed SignUp', err.code, err.message);
      throw new Error(err.code);
    }
  }
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (prefs, { getState }) => {
    const db = getFirestore();

    const user = getState().user.user;

    // console.error('Writing Prefs', prefs);
    await updateDoc(doc(db, 'users', user.uid), {
      ...prefs
    });

    const newUser = {
      ...user,
      ...prefs
    };

    const publicData = {
      bg: newUser.bg,
      photo: newUser.photo,
      first: newUser.first,
      last: newUser.last,
      title: newUser.title || '',
      _smeta: {}
    };
    [
      ...triGram(user.first),
      ...triGram(user.last),
      ...triGram(user.name)
    ].forEach(key => (publicData._smeta[key] = true));
    await updateDoc(doc(db, 'profile', user.uid), publicData);

    return newUser;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.initializing = false;
      state.user = action.payload;
      if (debugEnabled) {
        console.error('Set User', state.user);
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase('config/setKey', (state, action) => {
        if (!state.user || !action.payload) return;
        if (action.payload.key !== 'isDark') return;
        state.user.isDark = action.payload.value;
        const db = getFirestore();
        updateDoc(doc(db, 'users', state.user.uid), {
          isDark: state.user.isDark
        });
      })
      .addCase(updateUser.pending, state => {
        state.isPending = true;
        state.error = undefined;
      })
      .addCase(updateUser.fulfilled, state => {
        state.isPending = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isPending = false;
        state.error = action.payload;
      })
      .addCase(logOut.fulfilled, state => {
        state.user = null;
      })
      .addCase(logIn.pending, state => {
        state.isPending = true;
        state.error = undefined;
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.isPending = false;
        console.error('LOGGED IN SUCCESS', action);
      })
      .addCase(logIn.rejected, (state, action) => {
        state.isPending = false;
        state.error = action.payload;
        // toast.error('Failed login');
        console.error('LOGGED IN FAILED', action);
      });
  }
});

export const { setUser } = userSlice.actions;

export const selectUser = state => state.user.user;
export const selectUserIsPending = state => state.user.isPending;
export const selectUserError = state => state.user.error;

export default userSlice.reducer;

export const userMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState();
    if (debugEnabled) {
      console.error('MIDDLEWARELOGGING', action.type, state);
    }

    switch (action.type) {
      case 'user/logIn/fulfilled':
      case 'user/providerLogin/fulfilled':
        history.push('/');
        break;

      case 'user/logOut/fulfilled':
        history.push('/authentication/card/logout');
        break;

      case 'user/updateUser/fulfilled':
        dispatch(setUser(action.payload));
        break;
    }
    next(action);
  };
