import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const loaderSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    showLoader: (state, action) => {
      if (state[action.payload] === undefined) {
        if (Object.keys(state) > 100) {
          throw new Error('Too many loaders present!');
        }
      }
      state[action.payload] = true;
    },
    hideLoader: (state, action) => {
      delete state[action.payload];
    }
  }
});

export const { showLoader, hideLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
