import logo from 'assets/img/illustrations/icon-builder.png';

const iconBuilderSiteConfig = {
  global: {
    name: 'Icon Builder',
    homeRoute: '/app/icon-builder',
    homeRouteAuthenticated: '/app/icon-builder',
    logo,
    isFluid: false,
    isRTL: false,
    isDark: false,
    navbarPosition: 'top',
    showBurgerMenu: false, // controls showing vertical nav on mobile
    currency: '$',
    isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
    navbarStyle: 'transparent',
    navRoutes: []
  }
};

export default iconBuilderSiteConfig;
