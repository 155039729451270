import logo from 'assets/img/illustrations/demos.png';

const demosSiteConfig = {
  global: {
    name: 'Demos',
    homeRoute: '/app/demos',
    homeRouteAuthenticated: '/app/demos',
    logo,
    isFluid: false,
    isRTL: false,
    isDark: false,
    navbarPosition: 'vertical',
    showBurgerMenu: false, // controls showing vertical nav on mobile
    currency: '$',
    isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
    navbarStyle: 'transparent',
    navRoutes: [
      {
        label: 'Demos',
        labelDisable: false,
        children: [
          {
            name: 'Firestore',
            expanded: true,
            active: true,
            icon: 'layer-group',
            children: [
              {
                name: 'Pagination',
                to: '/app/demos/pagination',
                exact: true,
                active: true
              },
              {
                name: 'Searching Posts',
                to: '/app/demos/search',
                exact: true,
                active: true
              }
            ]
          }
        ]
      }
    ]
  }
};

export default demosSiteConfig;
