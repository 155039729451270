import { configureStore } from '@reduxjs/toolkit';
import userReducer, { userMiddleware } from 'reducers/userslice';
import counterReducer from 'reducers/counterSlice';
import configReducer from 'reducers/configSlice';
import postReducer from 'reducers/postSlice';
import contactReducer from 'reducers/contactSlice';
import loaderReducer from 'reducers/loaderSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    config: configReducer,
    post: postReducer,
    contact: contactReducer,
    loader: loaderReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([userMiddleware])
});
