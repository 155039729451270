import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addDoc, collection, getFirestore } from 'firebase/firestore';

const initialState = {
  name: '',
  email: '',
  phone: '',
  message: '',
  subject: '',
  submitting: false
};

/*
{
  to: ['support@vizure.net'],
  subject: 'Hello world',
  message: 'This is a message'
}
*/
export const submitContact = createAsyncThunk(
  'contact/submit',
  async (_args, { getState }) => {
    const {
      config: { name: siteName },
      contact: { name, email, phone, message }
    } = getState();

    const db = getFirestore();
    const payload = {
      to: ['support@vizure.net'],
      message: {
        subject: `${siteName} message from ${email} [${name}]`,
        text: [
          name,
          email,
          phone,
          message,
          '\n',
          window.location.hostname
        ].join('\n')
      }
    };

    const docRef = await addDoc(collection(db, 'mail'), payload);

    return docRef.id;
  }
);

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setContact: (state, action) => {
      Object.assign(state, action.payload);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(submitContact.pending, state => {
        state.submitting = true;
      })
      .addCase(submitContact.fulfilled, state => {
        state.submitting = false;
      })
      .addCase(submitContact.rejected, state => {
        state.submitting = false;
      });
  }
});

export const { setContact } = contactSlice.actions;

export default contactSlice.reducer;
